
.banner_child {
  .right_banner {
    width: 100%;
    max-height: 300px;
  }
}
.video_child {
  .right_video {
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    margin-bottom: 24px;
  }
}
.notfound {
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}
.right-panel {
  // @media screen and (max-width: "1366px") {
  //   width: 410px;
  // }
  @media screen and (max-width: "768px") {
    display: none;
  }
  width: 410px;
  min-width: 410px;
  margin-left: 10px;
  @media screen and (max-width: 768px) {
    min-width: 250px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    min-width: 220px;
  }
  img {
    width: 100%;
    height: auto;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  .sportbookwidget {
    margin-bottom: 24px;
    background: #fff;
    background: #fff;
    border: #d7d7d7 solid thin;
    height: auto;
    .title {
      padding: 10px 15px;
      font-size: 16px;
      font-weight: 500;
    }

    .contentbook {
      padding: 9px 15px;
      background: #f2f2f2;
      margin-top: 2px;

      .sportflex {
        display: flex;
        align-items: center;

        .offerpic {
          width: 50px;
          min-width: 50px;
          height: 50px;
          overflow: hidden;
          border-radius: 8px;
          margin-right: 15px;

          img {
            height: 100% !important;
            object-fit: cover;
            object-position: top center;
            width: 100% !important;
          }
        }

        .offercont {
          span {
            font-size: 14px;
            font-weight: 500;
            color: #000000;

            @media screen and (min-width: 1026px) and (max-width: 1299px) {
              font-size: 11px;
            }
          }
          p {
            font-size: 15px;
            font-weight: 500;
            color: #000000;
            @media screen and (min-width: 1026px) and (max-width: 1299px) {
              font-size: 12px;
            }
          }
        }
      }

      .offerbtn {
        display: flex;
        margin-top: 15px;
        gap: 5px;

        a {
          text-decoration: none;
          flex: 0 0 50%;
          flex-shrink: 1;
          flex-direction: column;
          display: flex;
          justify-content: center;
          background: #2e305c;
          padding: 8px 12px;
          text-align: center;
          border-radius: 5px;
          margin-right: 10px;

          .codepromo {
            color: #fff;
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 600;
            @media screen and (min-width: 1026px) and (max-width: 1299px) {
              font-size: 12px;
            }
          }
          .codetext {
            font-size: 10px;
            color: #fff;
          }
        }

        .claimoffer {
          text-decoration: none;
          flex: 0 0 50%;
          flex-shrink: 1;
          background: #fa8c28;
          color: #ffffff;
          padding: 12px;
          font-size: 15px;
          font-weight: 600;
          border-radius: 5px;
          @media screen and (min-width: 1026px) and (max-width: 1299px) {
            font-size: 12px;
          }
        }
      }
    }
    .no-bonus {
      text-align: center;
      padding-bottom: 15px;
    }
  }

  .sports-chat {
    background: #666dff;
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 24px;
    height: 50px;
    border-radius: 4px;
    display: flex;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    align-content: center;
    -webkit-align-content: center;
    justify-content: center;
    -webkit-justify-content: center;
    cursor: pointer;
    text-transform: uppercase;
    span {
      margin-left: 15px;
    }
    @media all and (max-width: 959px) {
      display: none;
    }
  }
  .sports-chat-area {
    position: fixed;
    bottom: -300%;
    right: 32px;
    width: 410px;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    z-index: 99;
    background: #fff;
    overflow: hidden;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    &.active {
      bottom: 0;
    }
    .title-area {
      display: flex;
      display: -webkit-flex;
      align-items: center;
      -webkit-align-items: center;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      background: #2e305c;
      padding: 10px;
      h5 {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        font-size: 16px;
        color: #fff;
        img {
          margin-left: 15px;
          max-width: 27px;
        }
      }
      .rules {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        button {
          background: #666dff;
          color: #fff;
          font-weight: 500;
          padding: 7px 20px;
          border-radius: 5px;
          outline: none;
          border: none;
          box-shadow: none;
        }
        span {
          font-size: 30px;
          color: #fff;
          line-height: 0px;
          margin-left: 12px;
          padding-right: 5px;
          cursor: pointer;
        }
      }
    }
    .chat-part {
      height: 400px;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 100px;
      }
      &::-webkit-scrollbar-track {
        background: rgb(235, 235, 235);
        border-radius: 100px;
      }
      &::-webkit-scrollbar-thumb {
        background: #666dff;
        border-radius: 100px;
      }
    }
    .rules-area {
      position: absolute;
      top: 0;
      width: 100%;
      left: -100%;
      bottom: 0;
      background: #fff;
      z-index: 99;
      &.active {
        left: 0;
      }
      h3 {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        background: #2e305c;
        padding: 10px;
        color: #fff;
        font-weight: 500;
        font-size: 16px;
        button {
          background: #666dff;
          color: #fff;
          font-weight: 500;
          padding: 7px 15px;
          border-radius: 5px;
          outline: none;
          border: none;
          box-shadow: none;
          font-size: 14px;
        }
      }
      .rules-content {
        padding: 25px;
        height: 400px;
        overflow-y: auto;
        p {
          margin-bottom: 15px;
          line-height: 20px;
        }
        ul {
          margin-bottom: 20px;
          li {
            list-style-type: none;
            border-left: 1px solid #2e305c;
            margin: 7px 0;
            background: #f1f1ff;
            padding: 10px;
            color: #2e305c;
          }
          &.rules-break {
            margin-bottom: 0px;
            li {
              background: #ffe5e5;
              color: #d73737;
              border-left: 1px solid #d73737;
            }
          }
        }
        h4 {
          color: #2e305c;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 17px;
          display: flex;
          display: -webkit-flex;
          align-items: center;
          -webkit-align-items: center;
          img {
            max-width: 25px;
            margin-left: 15px;
          }
        }
        &::-webkit-scrollbar {
          width: 5px;
          border-radius: 100px;
        }
        &::-webkit-scrollbar-track {
          background: rgb(235, 235, 235);
          border-radius: 100px;
        }
        &::-webkit-scrollbar-thumb {
          background: #666dff;
          border-radius: 100px;
        }
      }
    }
  }

  .rightvideo {
    margin-bottom: 24px;

    iframe {
      @media screen and (min-width: 1024px) and (max-width: 1366px) {
        aspect-ratio: 1.4;
        -webkit-aspect-ratio: 1.4;
        height: 200px;
      }
    }
  }
}
